/* eslint-disable react/prop-types */
import './SimpleBarChart.scss';

import PropTypes from 'prop-types';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { MONTH_NAMES } from '../../constants';

const SimpleBarChart = ({
  data, forecastYear, domain, tickCount,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatYAxis = tickItem => formatter.format(tickItem);

  const getBarColor = entry => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const entryMonth = MONTH_NAMES.indexOf(entry.name);
    if (currentYear === forecastYear) {
      if (entry.value > 0 && entryMonth <= currentMonth) {
        return '#2F855A';
      }
    }

    return (entry.value > 0 ? '#39C86A' : '#E53E3E');
  };

  return (
    <ResponsiveContainer width="100%" height="100%" maxHeight="500px">
      <BarChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis
          domain={domain || ['auto', 'auto']}
          padding={{ top: 10, bottom: 10 }}
          tickFormatter={formatYAxis}
          interval="preserveStartEnd"
          tickCount={tickCount || 5}
        />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="value" className="v" style={{ backgroundColor: 'black' }}>
          <Tooltip cursor={false} />
          {data.map((entry, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell className="cell" fill={getBarColor(entry)} key={idx}>
              <Tooltip cursor={false} />
            </Cell>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }

  return null;
};

SimpleBarChart.defaultProps = {
  forecastYear: '',
  domain: undefined,
  tickCount: undefined,
};

SimpleBarChart.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.func),
  tickCount: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.number,
  })).isRequired,
  forecastYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SimpleBarChart;
