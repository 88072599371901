import './SelectionInputs.scss';

import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Select,
  Slider, SliderFilledTrack,
  SliderThumb, SliderTrack,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import {
  COVERAGE_LEVELS,
  COVERAGE_PERCENTAGES,
  MAX_TIER1_COVERAGE,
  MAX_TIER2_COVERAGE,
  MIN_TIER1_COVERAGE,
  MIN_TIER2_COVERAGE,
  MIN_TIER2_PRODUCTION_HISTORY,
  YEAR_OPTIONS,
} from '../../constants';

const SelectionInputs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tier1Coverage, setTier1Coverage] = useState(MAX_TIER1_COVERAGE);
  const [tier2Coverage, setTier2Coverage] = useState(MIN_TIER2_COVERAGE);
  const [isTier2Disabled, setIsTier2Disabled] = useState(false);
  const [coveredProductionVal, setCoveredProductionVal] = useState(MIN_TIER2_PRODUCTION_HISTORY);
  const [timerId, setTimerId] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);
  const annualHistoricProductionRef = useRef(null);
  const tooltipRef = useRef(null);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatter4Decimals = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  const formatterWithoutCurrency = new Intl.NumberFormat('en-US');

  const formatterCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const globalState = {
    selectionData: useSelector(state => state.calculator.selectionData),
    selectionSlidersUpdate: useSelector(state => state.calculator.selectionSlidersUpdate),
    isLoading: useSelector(state => state.ui.isLoading),
  };

  const localActions = {
    setSelectionData: selectionData => dispatch(actions.calculator.setSelectionData(selectionData)),
    setUpdateSliders: updateSliders => dispatch(actions.calculator.setUpdateSliders(updateSliders)),
  };

  useEffect(() => {
    setTier1Coverage(globalState.selectionData.coverageLevel);
    setTier2Coverage(globalState.selectionData.tier2CoverageLevel);
    if (globalState.selectionData.coverageLevel <= MAX_TIER2_COVERAGE) {
      setIsTier2Disabled(true);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    if (globalState.selectionSlidersUpdate) {
      setTier1Coverage(globalState.selectionData.coverageLevel);

      if (globalState.selectionData.coveredProduction < MIN_TIER2_PRODUCTION_HISTORY) {
        setTier2Coverage(MIN_TIER2_COVERAGE);
        setIsTier2Disabled(true);
      } else if (globalState.selectionData.coverageLevel <= 8) {
        setTier2Coverage(globalState.selectionData.coverageLevel);
        setIsTier2Disabled(true);
      } else {
        setTier2Coverage(globalState.selectionData.tier2CoverageLevel);
        setIsTier2Disabled(false);
      }
      localActions.setUpdateSliders(false);
    }
  }, [globalState.selectionSlidersUpdate]);

  const onTier1CoverageChangeEnd = val => {
    let tier2CoverageLevel = null;
    if (globalState.selectionData.coveredProduction < MIN_TIER2_PRODUCTION_HISTORY) {
      tier2CoverageLevel = MIN_TIER2_COVERAGE;
      setIsTier2Disabled(true);
    } else if (val <= 8) {
      tier2CoverageLevel = val;
      setIsTier2Disabled(true);
    } else {
      tier2CoverageLevel = tier2Coverage;
      setIsTier2Disabled(false);
    }

    const tier1Choice = COVERAGE_LEVELS.indexOf(val);
    const tier2Choice = COVERAGE_LEVELS.indexOf(tier2CoverageLevel);

    localActions.setSelectionData({
      ...globalState.selectionData,
      coverageLevel: Number(val),
      tier2CoverageLevel: tier2CoverageLevel || globalState.selectionData.tier2CoverageLevel,
      tier1Choice,
      tier2Choice,
    });
  };

  const onTier2CoverageChangeEnd = val => {
    const tier1Choice = COVERAGE_LEVELS.indexOf(globalState.selectionData.coverageLevel);
    const tier2Choice = COVERAGE_LEVELS.indexOf(val);
    localActions.setSelectionData({
      ...globalState.selectionData,
      coverageLevel: globalState.selectionData.coverageLevel,
      tier2CoverageLevel: Number(val),
      tier1Choice,
      tier2Choice,
    });
  };

  const onTier1CoverageChange = val => {
    setTier1Coverage(val);
    if (globalState.selectionData.coveredProduction < MIN_TIER2_PRODUCTION_HISTORY) {
      setTier2Coverage(MIN_TIER2_COVERAGE);
      setIsTier2Disabled(true);
    } else if (val <= 8) {
      setTier2Coverage(val);
      setIsTier2Disabled(true);
    } else {
      setIsTier2Disabled(false);
    }
  };

  const onTier2CoverageChange = val => {
    if (Number(val) > MAX_TIER2_COVERAGE) {
      return;
    }
    setTier2Coverage(Number(val));
  };

  const updateSelectedYear = val => {
    if (!val) {
      return;
    }

    localActions.setSelectionData({
      ...globalState.selectionData,
      selectedYear: Number(val),
    });
  };

  const onCoveredProductionChange = value => {
    setCoveredProductionVal(value);
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
    const timer = setTimeout(() => {
      const coveredProduction = value;
      // eslint-disable-next-line max-len
      const coveredProductionPercentage = coveredProduction * globalState.selectionData.coveragePercentage;
      const tier1Production = Math.min(coveredProductionPercentage, MIN_TIER2_PRODUCTION_HISTORY);
      const data = {
        ...globalState.selectionData,
        coveredProduction,
        tier1Production,
        tier2Production: Math.max(coveredProductionPercentage - tier1Production, 0),
      };
      localActions.setSelectionData(data);
    }, 1000);
    setTimerId(timer);
  };

  useEffect(() => {
    setCoveredProductionVal(globalState.selectionData.coveredProduction);
  }, []);

  useEffect(() => {
    if (!globalState.isLoading) {
      annualHistoricProductionRef.current.focus();
    }
  }, [globalState.isLoading]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
        closeTooltip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef]);

  const onCoveragePercentageChange = e => {
    localActions.setSelectionData({
      ...globalState.selectionData,
      coveragePercentage: Number(e.target.value),
    });
  };

  return (
    <Flex
      className="content-container-selection-input"
    >
      <Flex
        direction="row"
        width={{ base: 'full' }}
        justifyContent="space-between"
        align="center"
        flexWrap="wrap"
        bgColor="#F7FAFC"
      >
        <Box margin="10px 10px 0 0">
          <Text className="input-label-selection">{t('selectionData.selectYear')}</Text>
          <Select
            className="input-text"
            value={globalState.selectionData.selectedYear}
            onChange={e => updateSelectedYear(e.target.value)}
            textAlign="end"
          >
            {
              YEAR_OPTIONS.map(year => (
                <option
                  value={year}
                  className={globalState.selectionData.selectedYear === year ? 'selected' : 'select-option'}
                  key={year}
                >
                  {year}
                </option>
              ))
            }
          </Select>
        </Box>

        <Box margin="10px 10px 0 0">
          <Flex ref={tooltipRef} alignItems="center" fontSize="12px" color="gray.500">
            <Text>{t('selectionData.annualHistoricProduction')}</Text>
            <Tooltip
              label={t('selectionData.annualHistoricProductionTooltip')}
              placement="bottom-end"
              isOpen={tooltipOpen}
              openDelay={500}
            >
              {!tooltipOpen
                ? (<InfoIcon marginStart="5px" color="blue.500" onMouseEnter={openTooltip} />)
                : (<CloseIcon marginStart="5px" color="blue.500" onClick={closeTooltip} />)}
            </Tooltip>
          </Flex>

          <NumberFormat
            getInputRef={annualHistoricProductionRef}
            value={coveredProductionVal}
            className="input-number"
            decimalScale={0}
            thousandSeparator
            onBlur={() => { }}
            onValueChange={values => onCoveredProductionChange(Number(values.value))}
            allowNegative={false}
            decimalSeparator="."
            displayType="input"
            type="text"
            disabled={globalState.isLoading}
          />
        </Box>

        <Box margin="10px 10px 0 0">
          <Text className="input-label-selection">{t('selectionData.coveragePercentage')}</Text>
          <Select
            value={globalState.selectionData.coveragePercentage}
            onChange={e => onCoveragePercentageChange(e)}
            backgroundColor="white"
          >
            {
              COVERAGE_PERCENTAGES.map(percentage => (
                <option
                  value={percentage}
                  key={percentage}
                >
                  {`${(percentage * 100).toFixed(0)}%`}
                </option>
              ))
            }
          </Select>
        </Box>

        <Box width="220px" margin="10px 10px 0 0">
          <Text className="input-label-selection">
            {t('selectionData.t1CoverageLevel')}
            <span className="slider-value" style={{ color: '#4299E1', fontWeight: '700' }}>{formatterCurrency.format(tier1Coverage)}</span>
          </Text>
          <Slider
            min={MIN_TIER1_COVERAGE}
            max={MAX_TIER1_COVERAGE}
            step={0.5}
            value={tier1Coverage}
            focusThumbOnChange={false}
            onChange={val => onTier1CoverageChange(Number(val))}
            onChangeEnd={val => onTier1CoverageChangeEnd(Number(val))}
            padding="0 !important"
            marginBottom="10px !important"
          >
            <SliderTrack bg="gray.200">
              <Box position="relative" right={10} />
              <SliderFilledTrack bg="#4299E1" />
            </SliderTrack>
            <SliderThumb boxSize={3} bg="blue.600" />
          </Slider>

          <Text className="input-label-selection">
            {t('selectionData.t2CoverageLevel')}
            <span
              className="slider-value"
              style={{ color: '#4299E1', fontWeight: '700' }}
            >
              {formatterCurrency.format(tier2Coverage)}
            </span>
          </Text>
          <Slider
            min={MIN_TIER2_COVERAGE}
            max={MAX_TIER1_COVERAGE}
            step={0.5}
            focusThumbOnChange={false}
            onChange={val => onTier2CoverageChange(val)}
            onChangeEnd={val => onTier2CoverageChangeEnd(val)}
            padding="0 !important"
            marginBottom="10px !important"
            isDisabled={isTier2Disabled}
            value={tier2Coverage}
          >
            <SliderTrack bg="gray.200">
              <Box position="relative" right={10} />
              <SliderFilledTrack bg="#4299E1" />
            </SliderTrack>
            <SliderThumb boxSize={3} bg="blue.600" />
          </Slider>
        </Box>
        <Table size="sm" width="300px" border="none">
          <Tbody>
            <Tr border="transparent 2px solid">
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">
                {t('selectionData.coverageLevel')}
              </Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{t('selectionData.choice')}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{t('selectionData.coveredProdHistory')}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{t('selectionData.totalPremium')}</Td>
            </Tr>
            <Tr border="transparent 2px solid" style={{ color: '#4299E1', fontWeight: '700' }}>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter.format(globalState.selectionData.coverageLevel)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter4Decimals.format(globalState.selectionData.tier1Level)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatterWithoutCurrency.format(globalState.selectionData.tier1Production)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter.format(globalState.selectionData.premium1)}</Td>
            </Tr>
            <Tr borderBottom="#A0AEC0 2px solid" style={{ color: '#4299E1', fontWeight: '700' }}>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter.format(globalState.selectionData.tier2CoverageLevel)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter4Decimals.format(globalState.selectionData.tier2Level)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatterWithoutCurrency.format(globalState.selectionData.tier2Production)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter.format(globalState.selectionData.premium2)}</Td>
            </Tr>
            <Tr border="transparent 2px solid" style={{ color: '#4299E1', fontWeight: '700' }}>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0" />
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0" />
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatterWithoutCurrency.format(globalState.selectionData.tier1Production + globalState.selectionData.tier2Production)}</Td>
              <Td textAlign="end" fontSize="12px" margin="4px" padding="0">{formatter.format(globalState.selectionData.premium1 + globalState.selectionData.premium2)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default SelectionInputs;
