import './translations/i18n';
import 'react-toastify/dist/ReactToastify.css';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';

ReactDOM.render(
  <StrictMode>
    <Router>
      <ChakraProvider>
        <ColorModeScript />
        <Routes />
        <ToastContainer autoClose={3000} hideProgressBar />
      </ChakraProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
);
