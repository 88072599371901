import axios from 'axios';

// import { ApiRoot } from './endpoints';

export default {
  async post(path, param) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return axios.post(process.env.REACT_APP_API + path, param, { headers });
  },
  async get(path) {
    return axios.get(process.env.REACT_APP_API + path);
  },
};
