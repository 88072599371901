import { combineReducers } from 'redux';

import calculator from './calculator';
import ui from './ui';

const fn = () => combineReducers({
  calculator,
  ui,
});
export default fn;
