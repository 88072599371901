import {
  ChakraProvider,
  Flex,
  theme,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

// import Footer from '../../shared/Footer/Footer';
// import Header from '../../shared/Header/Header';

const DefaultLayout = ({ children }) => (
  <ChakraProvider theme={theme}>
    <Flex
      align="center"
      justify="space-between"
      direction="column"
      minHeight="100vh"
    >
      {
        // <Header />
      }
      <Flex
        align="start"
        direction="column"
        flexGrow="1"
        width={{ base: 'full' }}
      >
        { children }
      </Flex>
      {
        // <Footer />
      }

    </Flex>
  </ChakraProvider>
);

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
