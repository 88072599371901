export const MIN_TIER1_COVERAGE = 4;
export const MAX_TIER1_COVERAGE = 9.5;
export const MIN_TIER2_COVERAGE = 4;
export const MAX_TIER2_COVERAGE = 8;
export const MIN_TIER2_PRODUCTION_HISTORY = 5000000;
export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];
export const COVERAGE_PERCENTAGES = [
  0.95, 0.90, 0.85, 0.80, 0.75, 0.70, 0.65, 0.60, 0.55,
  0.50, 0.45, 0.40, 0.35, 0.30, 0.25, 0.20, 0.15, 0.10, 0.05,
];
export const YEAR_OPTIONS = [2021, 2022, 2023, 2024];
export const COVERAGE_LEVELS = [9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4];
