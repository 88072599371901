import './MilkPriceAnalysis.scss';

import {
  Flex,
  NumberInput,
  NumberInputField,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'simple-react-loader';

import * as actions from '../../../actions';
import { MONTH_NAMES } from '../../../constants';
import MilkPriceAnalysisService from '../../../Services/MilkPriceAnalysis.service';
import SimpleBarChart from '../../../shared/BarChart/SimpleBarChart';
import SelectionInputs from '../../../shared/SelectionInputs/SelectionInputs';

const minYTick = 17;

const MilkPriceAnalysis = () => {
  const { t } = useTranslation();
  const [localBasis, setLocalBasis] = useState(0);
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const globalState = {
    selectionData: useSelector(state => state.calculator.selectionData),
  };

  const localActions = {
    calculatePremiums: (param, errorCb) => dispatch(
      actions.calculator.calculatePremiums(param, errorCb),
    ),
  };

  const errorCB = error => {
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const onLocalBasisChange = e => {
    if (!e.target.value) {
      setLocalBasis(0);
    } else {
      setLocalBasis(Number(e.target.value));
    }
  };

  useEffect(async () => {
    setLoader(true);
    try {
      if (globalState.selectionData.coveredProduction > 0) {
        await localActions.calculatePremiums(null, errorCB, false);
      }
      // eslint-disable-next-line max-len
      const milkPriceAnalysisData = await MilkPriceAnalysisService.getData(globalState.selectionData.selectedYear);
      setData(milkPriceAnalysisData);
      setLoader(false);
    } catch (error) {
      errorCB(error);
      setLoader(false);
    }
  }, [
    globalState.selectionData.selectedYear,
    globalState.selectionData.coveredProduction,
    globalState.selectionData.coverageLevel,
    globalState.selectionData.tier2CoverageLevel,
    globalState.selectionData.coveragePercentage,
  ]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getTickCount = dataArr => {
    const start = Math.floor(Math.min(minYTick, ...dataArr));
    const end = Math.ceil(Math.max(...dataArr));
    const ticksNum = (end - start) * 2 + 1;

    return ticksNum;
  };

  return (
    <Flex
      direction="column"
      width={{ base: 'full' }}
      padding="0 0 50px 0"
    >
      {loader && (<Loader showLoader={loader} type="refresh" />)}

      <SelectionInputs />

      <Flex className="coverage-analysis-content-container">
        <Flex
          className="first-row"
          overflowX="auto"
        >
          <Flex
            direction="row"
            marginBottom="15px"
          >
            <div>
              <Text
                color="gray.500"
                fontSize="xs"
                marginBottom="5px"
              >
                {t('milkPriceAnalysis.localBasis')}
              </Text>
              <NumberInput defaultValue={localBasis} precision={2} width="82px">
                <NumberInputField onChange={onLocalBasisChange} />
              </NumberInput>
            </div>
          </Flex>

          <Table colorScheme="gray">
            <Thead bg="gray.200">
              <Tr>
                <Th>{t('milkPriceAnalysis.month').toUpperCase()}</Th>
                <Th>{t('milkPriceAnalysis.forecastFeedCost').toUpperCase()}</Th>
                <Th>{t('milkPriceAnalysis.coverageLevel').toUpperCase()}</Th>
                <Th>{t('milkPriceAnalysis.localBasis').toUpperCase()}</Th>
                <Th>{t('milkPriceAnalysis.milkPriceTrigger').toUpperCase()}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                (data.forecasts || []).map((row, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tr key={idx}>
                    <Td>{MONTH_NAMES[row.month - 1] }</Td>
                    <Td className="with-plus">{formatter.format(row.feedCost)}</Td>
                    <Td className="with-plus">{formatter.format(globalState.selectionData.coverageLevel)}</Td>
                    <Td className="with-equals">{formatter.format(localBasis)}</Td>
                    <Td>
                      {formatter.format(
                        row.feedCost + globalState.selectionData.coverageLevel + localBasis,
                      )}
                    </Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
          <Text
            color="gray.500"
            fontSize="xs"
            margin="10px 0"
          >
            {t('milkPriceAnalysis.info', { currentDate: moment(data.forecastDate).format('MMMM DD, YYYY') })}
          </Text>
        </Flex>

        <Flex
          className="second-row"
        >
          <Text
            fontSize="lg"
            marginLeft="80px"
          >
            {t('historicAnalysis.DMCNetBenefit')}
          </Text>
          <Text
            color="gray.500"
            fontSize="xs"
            marginLeft="80px"
            marginBottom="40px"
          >
            {t('historicAnalysis.DMCNetBenefitInfo')}
          </Text>
          <SimpleBarChart
            // eslint-disable-next-line max-len
            domain={[dataMin => (Math.floor(Math.min(minYTick, dataMin))), dataMax => (Math.ceil(dataMax))]}
            // eslint-disable-next-line max-len
            tickCount={getTickCount((data.forecasts || []).map(row => row.feedCost + globalState.selectionData.coverageLevel + localBasis))}
            forecastYear={Number(globalState.selectionData.selectedYear)}
            data={(data.forecasts || []).map(row => ({
              name: MONTH_NAMES[row.month - 1],
              value: row.feedCost + globalState.selectionData.coverageLevel + localBasis,
            }))}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MilkPriceAnalysis;
