import './Navigation.scss';

import {
  Flex,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Navigation = ({ selectedIndex }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      bg="#900021"
      width={{ base: 'full' }}
      padding="0 10%"
      color="white"
      height="45px"
    >
      <Text
        bg={selectedIndex === 0 ? '#590114' : '#900021'}
        className="nav-item"
        onClick={() => history.push('/')}
      >
        {t('navigation.selectionAndAnalysis')}
      </Text>
      <Text
        bg={selectedIndex === 1 ? '#590114' : '#900021'}
        className="nav-item"
        onClick={() => history.push('/net-benefit-estimator')}
      >
        {t('navigation.netBenefitEstimator')}
      </Text>
      <Text
        bg={selectedIndex === 2 ? '#590114' : '#900021'}
        className="nav-item"
        onClick={() => history.push('/price-forecasts')}
      >
        {t('navigation.priceForecasts')}
      </Text>
      <Text
        bg={selectedIndex === 3 ? '#590114' : '#900021'}
        className="nav-item"
        onClick={() => history.push('/historic-performance')}
      >
        {t('navigation.historicPerformance')}
      </Text>
      <Text
        bg={selectedIndex === 4 ? '#590114' : '#900021'}
        className="nav-item"
        onClick={() => history.push('/milk-price-payment-trigger-analysis')}
      >
        {t('navigation.milikPricePaymentTriggerAnalysis')}
      </Text>
    </Flex>
  );
};

Navigation.defaultProps = {
  selectedIndex: 0,
};

Navigation.propTypes = {
  selectedIndex: PropTypes.number,
};

export default Navigation;
