import './HistoricAnalysis.scss';

import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'simple-react-loader';

import * as actions from '../../../actions';
import HistoricAnalysisService from '../../../Services/HistoricAnalysis.service';
import SimpleBarChart from '../../../shared/BarChart/SimpleBarChart';
import SelectionInputs from '../../../shared/SelectionInputs/SelectionInputs';
import YearSelect from '../../../shared/YearSelect/YearSelect';

const HistoricAnalysis = () => {
  const { t } = useTranslation();
  const [startYear, setStartYear] = useState(2009);
  const [endYear, setEndYear] = useState(moment().year());
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const globalState = {
    selectionData: useSelector(state => state.calculator.selectionData),
  };

  const localActions = {
    calculatePremiums: (param, errorCb) => dispatch(
      actions.calculator.calculatePremiums(param, errorCb),
    ),
  };

  const errorCb = error => {
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const updateStartYear = e => {
    setStartYear(Number(e.target.value));
  };

  const updateEndYear = e => {
    setEndYear(Number(e.target.value));
  };

  useEffect(async () => {
    setLoader(true);
    const params = {
      startYear,
      endYear,
      coverageLevelTier1: globalState.selectionData.coverageLevel,
      coverageLevelTier2: globalState.selectionData.tier2CoverageLevel,
      coveredProductionTier1: globalState.selectionData.tier1Production,
      coveredProductionTier2: globalState.selectionData.tier2Production,
      coveredProduction: globalState.selectionData.coveredProduction,
      premium1Selected: globalState.selectionData.tier1Level,
      premium2Selected: globalState.selectionData.tier2Level,
    };

    try {
      const historicAnalysisData = await HistoricAnalysisService.getData(params);
      setData(historicAnalysisData);
      setLoader(false);
    } catch (error) {
      errorCb(error);
      setLoader(false);
    }
  }, [
    startYear,
    endYear,
    globalState.selectionData,
  ]);

  useEffect(async () => {
    if (globalState.selectionData.coveredProduction > 0) {
      await localActions.calculatePremiums(null, errorCb, false);
    }
  }, [
    globalState.selectionData.selectedYear,
    globalState.selectionData.coveredProduction,
    globalState.selectionData.coverageLevel,
    globalState.selectionData.tier2CoverageLevel,
    globalState.selectionData.coveragePercentage,
  ]);

  return (
    <Flex
      direction="column"
      width={{ base: 'full' }}
      padding="0 0 50px 0"
    >
      {loader && (<Loader showLoader={loader} type="refresh" />)}

      <SelectionInputs />

      <Flex
        className="historic-analysis-content-container"
      >
        <Flex
          className="first-row"
        >
          <Flex
            direction="row"
            marginBottom="15px"
          >
            <YearSelect
              title={t('historicAnalysis.startYear')}
              startYear={2000}
              endYear={moment().year()}
              selected={startYear}
              onYearChange={updateStartYear}
            />
            <YearSelect
              title={t('historicAnalysis.endYear')}
              startYear={2000}
              endYear={moment().year()}
              selected={endYear}
              onYearChange={updateEndYear}
            />
          </Flex>

          <Table variant="striped" colorScheme="gray">
            <Tbody>
              <Tr>
                <Td>{t('historicAnalysis.numOfMonths')}</Td>
                <Td isNumeric color="teal">{data.totalMonths}</Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.numOfMonthsWithPayout')}</Td>
                <Td isNumeric color="teal">{data.payoutMonths}</Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.averageDMCPremiumPerCwt')}</Td>
                <Td isNumeric color="teal">
                  <NumberFormat
                    value={data.averageDMCPremiumPerCwt}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={3}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.averagePaymentPerCwt')}</Td>
                <Td isNumeric color="teal">
                  <NumberFormat
                    value={data.averagePaymentPerCwt}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.maxPaymentPerCwt')}</Td>
                <Td isNumeric color="teal">
                  <NumberFormat
                    value={data.maxPaymentPerCwt}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.averageNetIndemnityPerCwt')}</Td>
                <Td isNumeric color="teal">
                  <NumberFormat
                    value={data.averageNetIndemnityPerCwt}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>{t('historicAnalysis.cumulativeNetIndemnity')}</Td>
                <Td isNumeric color="teal">
                  <NumberFormat
                    value={data.cumulativeNetIndemnity}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Text
            color="gray.500"
            fontSize="xs"
            margin="10px 0"
          >
            {t('historicAnalysis.info')}
          </Text>
        </Flex>

        <Flex
          className="second-row"
        >
          <Text
            fontSize="lg"
            marginLeft="80px"
          >
            {t('historicAnalysis.DMCNetBenefit')}
          </Text>
          <Text
            color="gray.500"
            fontSize="xs"
            marginLeft="80px"
            marginBottom="40px"
          >
            {t('historicAnalysis.DMCNetBenefitInfo')}
          </Text>
          <SimpleBarChart data={(data.payments || []).map(x => ({
            name: x.year,
            value: x.amount,
          }))}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HistoricAnalysis;
