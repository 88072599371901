const engTranslations = {
  translations: {
    header: {
      disclaimer: 'Disclaimer',
      about: 'About',
      login: 'Login',
      logoImage: 'LogoImage',
    },
    footer: {
      copyright: '© 2021 MARK STEPHENSON, UNIVERSITY OF WISCONSIN-MADISON, ALL RIGHTS RESERVED.',
    },
    selectionData: {
      selectYear: 'Select Year:',
      annualHistoricProduction: 'Annual Historic Production',
      annualHistoricProductionTooltip: 'Historic production may be a dairy operation established DMC production history or DMC established adjusted base production history which is the combination of production history and supplemental production history established during 2021, 2022 or 2023 if applicable. '
        + 'Additionally, eligible dairy operations that did not update through SDMC in 2021, 2022, or 2023 may do so as adjusted base production history using the same supplemental formula (2019 milk marketings minus production history with the result multiplied by 75 percent) and combined with production history to become adjusted base production history.'
        + 'The County Office will have a record of the production histories on file if established by the dairy operation.  When most DMC dairy operations established production history, was the highest of milk production for the years of 2011, 2012, and 2013.  Since 2014, new dairy operations established a production history to participate in MPP-Dairy or DMC.  '
        + 'In 2021 eligible dairy operations updated their production history through supplemental DMC.  For 2024 DMC, adjusted base production history is established from the combination of production history and supplemental production.  Your historic production will not change once established for Dairy Margin Coverage program regardless of changes in the amount of milk that you sell. ',
      coveragePercentage: 'Coverage Percentage:',
      coveredAnnualProduction: 'Covered Annual Production',
      tier1: 'Tier 1',
      tier2: 'Tier 2',
      unit: 'lbs',
      annualPremiumAndFee: 'Annual Premium & Fee',
      averagePremiumPerCwt: 'Average Premium per cwt',
      coveredProductionHistory: 'Covered Production History (lbs):',
      t1CoverageLevel: 'Tier 1 Coverage Level:',
      t2CoverageLevel: 'Tier 2 Coverage Level:',
      coverageLevel: 'Coverage Level',
      choice: 'Choice ($/cwt)',
      coveredProdHistory: 'Covered Prod History (lbs)',
      totalPremium: 'Total Premium ($/cwt)',
    },
    coverageSelectionAndAnalysisPage: {
      title: 'Dairy Margin Coverage Decision Tool',
      calculator: {
        selectionTitle: 'Coverage Selection',
        tiers: {
          tier1: 'Tier 1',
          tier2: 'Tier 2',
        },
        table: {
          coverageLevel: 'Coverage Level',
          choice: 'Choice',
          premium: 'Premium',
          na: 'na',
        },
      },
    },
    navigation: {
      selectionAndAnalysis: 'Coverage Selection',
      forecastMargin: 'Forecast Margin',
      futureAndPrices: 'Forecast Future and Government Prices',
      alerts: 'Alerts',
      netBenefitEstimator: 'Net Benefit Forecasts',
      historicPerformance: 'Historic Performance',
      milikPricePaymentTriggerAnalysis: 'Milk Price Payment Trigger Analysis',
      priceForecasts: 'Price Forecasts',
    },
    historicAnalysis: {
      title: 'Historic Performance',
      startYear: 'Start Year*',
      endYear: 'End Year',
      numOfMonths: 'Total number of months',
      numOfMonthsWithPayout: 'Number of months with payout',
      averageDMCPremiumPerCwt: 'Average DMC premium per cwt',
      averagePaymentPerCwt: 'Average payment per cwt',
      maxPaymentPerCwt: 'Max payment per cwt',
      averageNetIndemnityPerCwt: 'Average net indemnity per cwt',
      cumulativeNetIndemnity: 'Cumulative net indemnity',
      info: '*Start years prior to the beginning of the DMC program in 2019 are estimates',
      DMCNetBenefit: 'DMC Net Benefit',
      DMCNetBenefitInfo: 'Indemnity payments minus premiums',
    },
    milkPriceAnalysis: {
      title: 'DMC Milk Price Payment Trigger Analysis',
      forecastYear: 'Forecast Year',
      info: '*Feed Cost were estimated based on futures market values as of {{currentDate}}',
      localBasis: 'Local Basis',
      month: 'Month',
      forecastFeedCost: 'Forecast Feed Cost',
      coverageLevel: 'Coverage Level',
      milkPriceTrigger: 'Milk Price Trigger',
    },
    netBenefitEstimator: {
      title: 'Net Benefit Estimator',
      subtitles: {
        DMCNetBenefitEstimator: 'Dairy Margin Net Benefit Estimator',
        priceAndMarginForecast: 'Price and Margin Forecast',
      },
      forecastDateInfo: '*Price and margin estimate date: {{currentDate}}',
      tableHeaders: {
        month: 'Month',
        forcastedMargin: 'Actual / Forecasted Margin',
        t1ProdHistory: 'Tier 1 Covered production history (lbs)',
        t2ProdHistory: 'Tier 2 Covered production history (lbs)',
        t1CoveredPayment: 'Tier 1 Forecasted Payment Per Cwt',
        t2CoveredPayment: 'Tier 2 Forecasted Payment per Cwt',
        totalPayment: 'Total Payment',
        milkForecast: 'All Milk Price forecast ($/cwt)',
        cornForecast: 'Corn Price forecast ($/bu)',
        alfalfaHayForecast: 'premium/supreme alfalfa hay price forecast ($/ton)',
        soybeanForecast: 'SOYBEAN MEAL PRICE FORECAST ($/TON)',
        feedCostForecast: 'Feed cost forecast ($/cwt)',
        DMCMarginForecast: 'DMC Margin forecast ($/cwt)',
      },
    },
  },
};

export default engTranslations;
