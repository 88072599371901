import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import { CoverageSelectionAndAnalysisPage } from './pages/CoverageSelectionAndAnalysisPage/CoverageSelectionAndAnalysisPage';
import { HistoricPerformance } from './pages/HistoricPerformance/HistoricPerformace';
import { MilkPricePaymentTriggerAnalysis } from './pages/MilkPricePaymentTriggerAnalysis/MilkPricePaymentTriggerAnalysis';
import NetBenefitEstimator from './pages/NetBenefitEstimator/NetBenefitEstimator';
import { PriceForecasts } from './pages/PriceForecasts/PriceForecasts';
import createRootReducer from './reducers';

const preloadedState = undefined;

const store = createStore(
  createRootReducer(),
  preloadedState,
  composeWithDevTools(applyMiddleware(ReduxThunk)),
);

const Routes = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" exact component={CoverageSelectionAndAnalysisPage} />
        <Route path="/net-benefit-estimator" exact component={NetBenefitEstimator} />
        <Route path="/historic-performance" exact component={HistoricPerformance} />
        <Route path="/milk-price-payment-trigger-analysis" exact component={MilkPricePaymentTriggerAnalysis} />
        <Route path="/price-forecasts" exact component={PriceForecasts} />
      </Switch>
    </Router>
  </Provider>

);

export default Routes;
