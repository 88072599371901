import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './languages/en';

const resources = {
  en,
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    load: 'languageOnly',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    interpolation: {
      escapeValue: false,
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18next;
