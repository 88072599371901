import CalculatorService from '../Services/Calculator.service';
import * as types from './actionTypes';

export function setSelectionData(selectionData) {
  return async dispatch => {
    dispatch({ type: types.SET_SELECTION_DATA, selectionData });
  };
}

export function setUpdateSliders(updateSliders) {
  return async dispatch => {
    dispatch({ type: types.SELECTION_SLIDERS_UPDATE, updateSliders });
  };
}

export function setUpdateTableIndexes(updateIndexes) {
  return async dispatch => {
    dispatch({ type: types.UPDATE_TABLE_INDEXES, updateIndexes });
  };
}

export function setPremiums(premiums) {
  return async dispatch => {
    dispatch({ type: types.SET_PREMIUMS, premiums });
  };
}

export function calculatePremiums(param, errorCb) {
  return async (dispatch, getState) => {
    const state = await getState();
    const { selectionData } = state.calculator;

    const requestData = {
      annualHistoricProduction: selectionData.coveredProduction,
      coveragePercentage: selectionData.coveragePercentage,
      tier1Level: selectionData.tier1Level,
      tier2Level: selectionData.tier2Level,
    };

    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });
      const premiumData = await CalculatorService.CalculatePremiums(requestData);
      if (premiumData.premiums) {
        dispatch({ type: types.SET_PREMIUMS, premiums: premiumData.premiums });

        const data = {
          tier1Level: premiumData.premiums[selectionData.tier1Choice].level.tierLevels.tier1,
          tier2Level: premiumData.premiums[selectionData.tier2Choice].level.tierLevels.tier2,
          tier1Production: premiumData.tier1,
          tier2Production: premiumData.tier2,
          premium1: premiumData.premiums[selectionData.tier1Choice].tier1Premium,
          premium2: premiumData.premiums[selectionData.tier2Choice].tier2Premium,
        };

        dispatch({
          type: types.SET_SELECTION_DATA,
          selectionData: {
            ...selectionData,
            ...data,
          },
        });
      }
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
      if (errorCb) errorCb(error);
    }
  };
}
