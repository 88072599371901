import './Calculator.scss';

import {
  Flex,
  Radio,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'simple-react-loader';

import * as actions from '../../../actions';
import SelectionInputs from '../../../shared/SelectionInputs/SelectionInputs';

const Calculator = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const globalState = {
    selectionData: useSelector(state => state.calculator.selectionData),
    premiums: useSelector(state => state.calculator.premiums),
    isLoading: useSelector(state => state.ui.isLoading),
  };

  const [tier1Choice, setTier1Choice] = useState(0);
  const [tier2Choice, setTier2Choice] = useState(3);

  const localActions = {
    setSelectionData: selectionData => dispatch(actions.calculator.setSelectionData(selectionData)),
    setUpdateSliders: updateSliders => dispatch(actions.calculator.setUpdateSliders(updateSliders)),
    calculatePremiums: (param, errorCb) => dispatch(
      actions.calculator.calculatePremiums(param, errorCb),
    ),
  };
  const errorCb = error => {
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    if (globalState.premiums) {
      globalState.premiums.map((data, index) => {
        if (globalState.selectionData.coverageLevel === data.level.level) {
          setTier1Choice(index);
        }
        if (globalState.selectionData.tier2CoverageLevel === data.level.level) {
          setTier2Choice(index);
        }
        return data;
      });
    }
  }, [
    globalState.premiums,
    globalState.selectionData.coverageLevel,
    globalState.selectionData.tier2CoverageLevel,
  ]);

  useEffect(async () => {
    if (globalState.selectionData.coveredProduction <= 0) {
      return;
    }

    await localActions.calculatePremiums(null, errorCb);
  }, [
    globalState.selectionData.coveredProduction,
    globalState.selectionData.coveragePercentage,
    globalState.selectionData.selectedYear,
    globalState.selectionData.coverageLevel,
    globalState.selectionData.tier2CoverageLevel,
    globalState.selectionData.tier1Choice,
    globalState.selectionData.tier2Choice,
  ]);

  useEffect(async () => {
    await localActions.calculatePremiums(null, errorCb);
  }, []);

  const updateTier1Choice = idx => {
    let updateTier2 = false;
    setTier1Choice(idx);

    if (idx > 2) {
      setTier2Choice(idx);
      updateTier2 = true;
    }

    localActions.setSelectionData({
      ...globalState.selectionData,
      coverageLevel: globalState.premiums[idx].level.level,
      tier2CoverageLevel:
        updateTier2
          ? globalState.premiums[idx].level.level : globalState.selectionData.tier2CoverageLevel,
      tier1Choice: idx,
      tier2Choice: updateTier2 ? idx : globalState.selectionData.tier2Choice,
    });

    localActions.setUpdateSliders(true);
  };

  const updateTier2Choice = idx => {
    let updateTier1 = false;
    setTier2Choice(idx);

    if (tier1Choice > 2) {
      setTier1Choice(idx);
      updateTier1 = true;
    }

    localActions.setSelectionData({
      ...globalState.selectionData,
      coverageLevel: updateTier1
        ? globalState.premiums[idx].level.level : globalState.selectionData.coverageLevel,
      tier2CoverageLevel: globalState.premiums[idx].level.level,
      tier1Choice: updateTier1 ? idx : globalState.selectionData.tier1Choice,
      tier2Choice: idx,
    });

    localActions.setUpdateSliders(true);
  };

  return (
    <div className="coverage-container">
      {globalState.isLoading && (<Loader showLoader={globalState.isLoading} type="refresh" />)}

      <SelectionInputs />

      <Flex
        flexDirection="column"
        width="80%"
        margin="auto"
      >
        <Table>
          <Thead>
            <Tr>
              <Th />
              <Th bg="#FFEAEF" style={{ textAlign: 'center' }}>{t('coverageSelectionAndAnalysisPage.calculator.tiers.tier1')}</Th>
              <Th bg="#F7FAFC" style={{ textAlign: 'center' }}>{t('coverageSelectionAndAnalysisPage.calculator.tiers.tier2')}</Th>
            </Tr>
          </Thead>
        </Table>

        <Table colorScheme="gray">
          <Thead bg="gray.200" className="table-header">
            <Tr>
              <Th>{t('coverageSelectionAndAnalysisPage.calculator.table.coverageLevel')}</Th>
              <Th>{t('coverageSelectionAndAnalysisPage.calculator.table.choice')}</Th>
              <Th>{t('coverageSelectionAndAnalysisPage.calculator.table.premium')}</Th>
              <Th>{t('coverageSelectionAndAnalysisPage.calculator.table.choice')}</Th>
              <Th>{t('coverageSelectionAndAnalysisPage.calculator.table.premium')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {globalState.premiums && globalState.premiums.map((x, idx) => (
            /* eslint-disable react/no-array-index-key */
              <Tr key={idx}>
                <Td>
                  <NumberFormat
                    value={x.level.level}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
                <Td>
                  <Radio
                    onChange={() => { updateTier1Choice(idx); }}
                    name="tire1-level"
                    value={x.level.tierLevels.tier1}
                    isChecked={tier1Choice === idx}
                  >
                    <NumberFormat
                      value={x.level.tierLevels.tier1}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={4}
                      thousandSeparator
                      prefix="$"
                    />
                  </Radio>
                </Td>
                <Td>
                  <NumberFormat
                    value={x.tier1Premium}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                  />
                </Td>
                <Td>
                  {(x.level.tierLevels.tier2 === 0 || x.level.tierLevels.tier2) && (
                    <Radio
                      onChange={() => { updateTier2Choice(idx); }}
                      name="tire2-level"
                      value={x.level.tierLevels.tier2 || ''}
                      isChecked={tier2Choice === idx}
                    >
                      <NumberFormat
                        value={x.level.tierLevels.tier2}
                        displayType="text"
                        fixedDecimalScale
                        decimalScale={4}
                        thousandSeparator
                        prefix="$"
                      />
                    </Radio>
                  )}
                </Td>
                <Td>
                  {(x.level.tierLevels.tier2 === 0 || x.level.tierLevels.tier2) && (
                    <NumberFormat
                      value={x.tier2Premium}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator
                      prefix="$"
                    />
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>

    </div>
  );
};

export default Calculator;
