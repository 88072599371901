import api from '../api';
import { HistoricAnalysis } from '../endpoints';

const HistoricAnalysisService = {
  getData: async data => {
    const response = await api.post(HistoricAnalysis, data);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },
};

export default HistoricAnalysisService;
