import {
  Flex,
  Select,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const YearSelect = ({
  title, startYear, endYear, onYearChange, selected,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const years = [];
    let currentYear = endYear;
    while (currentYear >= startYear) {
      years.push(currentYear);
      currentYear -= 1;
    }
    setOptions(years);
  }, [startYear, endYear]);

  return (
    <Flex
      align="flex-start"
      justify="space-between"
      direction="column"
      marginRight="20px"
    >
      <Text
        color="gray.500"
        fontSize="xs"
        marginBottom="5px"
      >
        {title}
      </Text>
      <Select bg="white" onChange={onYearChange} value={selected && selected}>
        {
          options.map(year => (
            <option
              value={year}
              key={Math.random()}
            >
              {year}

            </option>
          ))
        }
      </Select>
    </Flex>
  );
};

YearSelect.defaultProps = {
  onYearChange: () => {},
  selected: '',
};

YearSelect.propTypes = {
  title: PropTypes.string.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  onYearChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default YearSelect;
