import { combineReducers } from 'redux';

import * as types from '../actions/actionTypes';

const selectionDataInitialState = {
  coverageLevel: 9.5,
  tier2CoverageLevel: 8,
  coveredProduction: 5000000,
  premium1: 7125,
  premium2: 0,
  tier1Level: 0.15,
  tier2Level: 1.8130,
  tier1Production: 4750000,
  tier2Production: 0,
  selectedYear: new Date().getFullYear(),
  coveragePercentage: 0.95,
  tier1Choice: 0,
  tier2Choice: 3,
};

const selectionData = (state = selectionDataInitialState, action) => {
  switch (action.type) {
    case types.SET_SELECTION_DATA:
      // eslint-disable-next-line no-case-declarations
      const newState = { ...action.selectionData };
      if (!newState.tier2Level) {
        newState.tier2Level = 0;
      }
      return newState;
    default:
      return state;
  }
};

const premiums = (state = [], action) => {
  switch (action.type) {
    case types.SET_PREMIUMS:
      return action.premiums;
    default:
      return state;
  }
};

const selectionSlidersUpdate = (state = false, action) => {
  switch (action.type) {
    case types.SELECTION_SLIDERS_UPDATE:
      return action.updateSliders;
    default:
      return state;
  }
};

const tableIndexesUpdate = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_TABLE_INDEXES:
      return action.updateIndexes;
    default:
      return state;
  }
};

export default combineReducers({
  selectionData,
  selectionSlidersUpdate,
  tableIndexesUpdate,
  premiums,
});
