import api from '../api';
import { MilkPriceAnalysis } from '../endpoints';

const MilkPriceAnalysisService = {
  getData: async year => {
    const response = await api.get(`${MilkPriceAnalysis}/${year}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },
};

export default MilkPriceAnalysisService;
