/* eslint-disable max-len */
import '../NetBenefitEstimator/NetBenefitEstimator.scss';

import {
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'simple-react-loader';

import * as actions from '../../actions';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import NetBenefitEstimatorService from '../../Services/NetBenefitEstimator.service';
import Navigation from '../../shared/Navigation/Navigation';
import SelectionInputs from '../../shared/SelectionInputs/SelectionInputs';
import { getMonthName } from '../../utils';

const PriceForecasts = () => {
  const { t } = useTranslation();
  const [estimatedData, setEstimatedData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const globalState = {
    selectionData: useSelector(state => state.calculator.selectionData),
  };

  const localActions = {
    calculatePremiums: (param, errorCb) => dispatch(
      actions.calculator.calculatePremiums(param, errorCb),
    ),
  };

  const formatterCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatterCurrencyInteger = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const errorCB = error => {
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const fetchEstimatedData = async () => {
    setLoader(true);

    const params = {
      year: globalState.selectionData.selectedYear,
      coveredProduction: globalState.selectionData.coveredProduction,
      coveragePercentage: globalState.selectionData.coveragePercentage,
      tier1Level: globalState.selectionData.coverageLevel,
      tier2Level: globalState.selectionData.tier2CoverageLevel,
    };

    try {
      if (globalState.selectionData.coveredProduction > 0) {
        await localActions.calculatePremiums(null, errorCB, false);
      }

      const data = await NetBenefitEstimatorService.getEstimatedData(params);
      setEstimatedData(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchEstimatedData();
  }, [
    globalState.selectionData.selectedYear,
    globalState.selectionData.coveredProduction,
    globalState.selectionData.coverageLevel,
    globalState.selectionData.tier2CoverageLevel,
    globalState.selectionData.coveragePercentage,
  ]);

  return (
    <DefaultLayout>
      {loader && (<Loader showLoader={loader} type="refresh" />)}
      <Navigation selectedIndex={2} />
      <div className="net-benefit-estimator-container">

        <SelectionInputs />

        {
          estimatedData && estimatedData.benefitEstimates
          && estimatedData.forecasts && !!estimatedData.forecasts.length
            && (
              <div className="table-container">
                <Table>
                  <Thead bg="gray.200">
                    <Tr color="gray.600">
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.month').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.milkForecast').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.cornForecast').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.alfalfaHayForecast').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.soybeanForecast').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.feedCostForecast').toUpperCase()}</Th>
                      <Th textAlign="end">{t('netBenefitEstimator.tableHeaders.DMCMarginForecast').toUpperCase()}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      estimatedData.forecasts.map(tableRow => (
                        <Tr key={tableRow.month}>
                          <Td textAlign="end">{getMonthName(tableRow.month)}</Td>
                          <Td textAlign="end">{formatterCurrency.format(tableRow.allMilkPrice)}</Td>
                          <Td textAlign="end">{formatterCurrency.format(tableRow.cornPrice)}</Td>
                          <Td textAlign="end">{formatterCurrencyInteger.format(tableRow.hayPrice)}</Td>
                          <Td textAlign="end">{formatterCurrency.format(tableRow.soybeanMealprice)}</Td>
                          <Td textAlign="end">{formatterCurrency.format(tableRow.feedCost)}</Td>
                          <Td textAlign="end">{formatterCurrency.format(tableRow.dmcMarginForecast)}</Td>
                        </Tr>
                      ))
                    }
                  </Tbody>
                  {
                    estimatedData.forecasts
                  && (
                    <Tfoot>
                      <Tr bgColor="gray.100">
                        <Td textAlign="end">{globalState.selectionData.selectedYear}</Td>
                        <Td textAlign="end">{formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.allMilkPrice, 0) / estimatedData.forecasts.length)}</Td>
                        <Td textAlign="end">
                          {formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.cornPrice, 0) / estimatedData.forecasts.length)}
                        </Td>
                        <Td textAlign="end">
                          {formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.hayPrice, 0) / estimatedData.forecasts.length)}
                        </Td>
                        <Td textAlign="end">
                          {formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.soybeanMealprice, 0) / estimatedData.forecasts.length)}
                        </Td>
                        <Td textAlign="end">
                          {formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.feedCost, 0) / estimatedData.forecasts.length)}
                        </Td>
                        <Td textAlign="end">{formatterCurrency.format(estimatedData.forecasts.reduce((a, b) => a + b.dmcMarginForecast, 0) / estimatedData.forecasts.length)}</Td>
                      </Tr>
                    </Tfoot>
                  )
                  }

                </Table>
                <Text
                  color="gray.500"
                  fontSize="xs"
                  margin="10px 0"
                >
                  {t('netBenefitEstimator.forecastDateInfo', { currentDate: moment(estimatedData.forecastDate).format('MMMM DD, YYYY') })}
                </Text>
              </div>
            )
        }
      </div>

    </DefaultLayout>
  );
};

export { PriceForecasts };
