import api from '../api';
import { CalculatePremiums, DefaultPremiums } from '../endpoints';

const CalculatorService = {
  CalculatePremiums: async data => {
    const response = await api.post(CalculatePremiums, data);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },
  Defaults: async () => {
    const response = await api.get(DefaultPremiums);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },
};

export default CalculatorService;
