import './CoverageSelectionAndAnalysisPage.scss';

import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Navigation from '../../shared/Navigation/Navigation';
import Calculator from './Calculator/Calculator';

const CoverageSelectionAndAnalysisPage = () => (
  <DefaultLayout>
    <Navigation />
    <div className="main-container-calculator">
      <Calculator />
    </div>
  </DefaultLayout>
);

export { CoverageSelectionAndAnalysisPage };
