import api from '../api';
import { NetBenefitEstimate } from '../endpoints';

const NetBenefitEstimatorService = {
  getEstimatedData: async data => {
    const response = await api.post(NetBenefitEstimate, data);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },
};

export default NetBenefitEstimatorService;
