import './HistoricPerformance.scss';

import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Navigation from '../../shared/Navigation/Navigation';
import HistoricAnalysis from '../CoverageSelectionAndAnalysisPage/HistoricAnalysis/HistoricAnalysis';

const HistoricPerformance = () => (
  <DefaultLayout>
    <Navigation selectedIndex={3} />
    <div className="main-container-historic-performance">
      <HistoricAnalysis />
    </div>
  </DefaultLayout>
);

export { HistoricPerformance };
