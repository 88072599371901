import './MilkPricePaymentTriggerAnalysis.scss';

import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Navigation from '../../shared/Navigation/Navigation';
import MilkPriceAnalysis from '../CoverageSelectionAndAnalysisPage/MilkPriceAnalysis/MilkPriceAnalysis';

const MilkPricePaymentTriggerAnalysis = () => (
  <DefaultLayout>
    <Navigation selectedIndex={4} />
    <div className="main-container-milk-price">
      <MilkPriceAnalysis />
    </div>
  </DefaultLayout>
);

export { MilkPricePaymentTriggerAnalysis };
